import Founder from "../Founder";
import { FacebookIcon, InstagramIcon, YoutubeIcon } from "../../assets/Icons";
import Newsletter from "../Newsletter";
import CtaSection from "../CtaSection";

function About() {
  return (
    <main id="about" className="">
      <section id="hero" className="hero relative">
        <div className="flex flex-col justify-center h-[50svh] bg-black bg-opacity-50 lg:h-[60svh]">
          <div className="text-[#f3f3f3] text-center space-y-3 mt-[15vh] w-[92%] md:max-w-[90vw] mx-auto md:ml-[6%]">
            <h1
              data-aos="fade-up"
              data-aos-offset="200"
              className="text-6xl -mb-3 tracking-tighter sm:text-7xl xl:text-[102px]"
            >
              About Us
            </h1>

            <p
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="200"
              className="text-xs max-w-md mx-auto sm:text-sm leading-snug"
            >
              We believe in a brighter tomorrow for all.
            </p>
          </div>

          <div className="absolute bottom-0 w-full">
            <div className="flex items-center justify-end gap-2 mx-4 mb-6 md:flex-col md:items-end sm:mx-8">
              <a
                href="https://www.instagram.com/christopherandviolet?igsh=azR5ZmhvYmlsbTRu&utm_source=qr"
                // target="_blank"
                // rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                className="bgpink7"
                href="https://facebook.com"
                // target="_blank"
                // rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://youtube.com"
                // target="_blank" rel="noopener noreferrer"
              >
                <YoutubeIcon />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-amber-50 bg-opacity-5 py-12 px-[5%] md:py-16">
        <div className="max-w-7xl mx-auto space-y-16">
          <div className="lg:flex flex-row items-center justify-between gap-16">
            <div className="lg:w-[38%]">
              <p className="text-lg md:text-lg text-red-900 font-bold flex items-center">
                <hr className="border-none h-[2px] w-[20px] bg-red-900" />
                What We Do
              </p>
              <p className="text-gray-900 mb-4 text-5xl sm:text-6xl lg:text-7xl lg:mb-0">
                Our Impact Goes Beyond Immediate Beneficiaries.
              </p>
            </div>
            <div className="text-justify space-y-4 lg:w-[65%]">
              <p>
                The Christopher & Violet Foundation, a 501(c)(3) organization, is driven by a
                profound commitment to support and enhance the lives of children and young adults
                who find themselves alone in the world. Our mission is to provide them with a
                chance—a chance for a brighter future, enriched with opportunities, care, and
                guidance. Rooted in compassion and dedicated to making a lasting impact, we strive
                to be a beacon of hope, empowering the vulnerable to overcome adversity and discover
                their true potential.
              </p>
              <p>
                Empowering lives and inspiring hope, our foundation envisions a world where every
                individual, regardless of circumstance, has the opportunity to thrive. We strive to
                create lasting positive change by championing education, healthcare, and community
                development initiatives. Through compassion, collaboration, and sustainable
                solutions, we aim to build a future where everyone can reach their full potential
                and contribute to the well-being of society. Together, we believe in a brighter
                tomorrow for all.
              </p>
              <p>
                The impact extends beyond the immediate beneficiaries you see, but redefine the
                measure of success, shifting it from personal achievements to the welfare of
                humanity.
              </p>
              <p>
                Supporting the children and young adults in this community is a reminder that our
                true worth is measured not just by personal gain but by our commitment to uplifting
                others, our foundation envisions a community where children and young adults are
                empowered to flourish. We are dedicated to nurturing lives, providing opportunities,
                and building a future that is inclusive and compassionate. By honoring the potential
                in every individual, we strive to pave the way for a world where collective care and
                support create pathways to brighter tomorrows for all.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mission_vision">
        <div className="bg-sky-50 bg-opacity-95">
          <div className="bg-black bg-opacity-5 text-[#e9e5e5] px-5 py-14 sm:px-8 sm:py-24">
            <p
              data-aos="fade-up"
              data-aos-offset="200"
              className="max-w-6xl mx-auto text-gray-900 text-5xl mb-20 sm:mb-24 md:text-7xl"
            >
              Mission & Vision Statement
            </p>

            <div
              data-aos="fade-up"
              data-aos-offset="200"
              className="flex flex-col gap-4 text-justifyz max-w-5xl mx-auto sm:flex-row"
            >
              <div className="relative bg-[#001f3f] rounded-md shadow-md px-[6%] py-10 w-full h-[380px] flex flex-col justify-center">
                <svg
                  className="absolute -top-3 -left-3 hidden sm:block md:-top-4 md:-left-4"
                  width="80"
                  height="80"
                  viewBox="0 0 249 248"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M248.5 8L20.5 8.00003C13.8726 8.00003 8.5 13.3726 8.5 20L8.50002 248"
                    stroke="#172554"
                    stroke-width="16"
                  />
                </svg>

                <p className="text-4xl mb-8 flex flex-col-reverse tems-center gap-1">
                  <hr className="border-none h-[0.3px] w-[120px] mt-2 bg-[#f3f3f3]" /> Our Mission
                </p>
                <p className="text-xl">
                  Focused on the overall improvement of youth & young adults and encouraging our
                  children in Nigeria to reach their highest potential by providing the necessary
                  resources
                </p>
              </div>
              <div className="relative bg-red-900 rounded-md shadow-md px-[6%] py-10 w-full h-[380px] flex flex-col justify-center">
                <svg
                  className="absolute hidden sm:block -scale-y-100 -scale-x-100 -bottom-3 -right-3 md:-bottom-4 md:-right-4"
                  width="80"
                  height="80"
                  viewBox="0 0 249 248"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M248.5 8L20.5 8.00003C13.8726 8.00003 8.5 13.3726 8.5 20L8.50002 248"
                    stroke="#7f1d1d"
                    stroke-width="16"
                  />
                </svg>

                <p className="text-4xl mb-8 flex flex-col-reverse tems-center gap-1">
                  <hr className="border-none h-[0.5px] w-[120px] mt-2 bg-[#f3f3f3]" /> Our Vision
                </p>
                <p className="text-xl">
                  A world where every individual has access to quality education, empowering them to
                  reach their full potential and contribute to the general betterment of society.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Founder />

      <CtaSection />

      <section className="cta-section hidden bg-fixed bg-cover bg-center">
        <div className="text-center text-[#f3f3f3] bg-black bg-opacity-30 backdrop-blur-[2px] px-5 py-20 md:py-24">
          <div className="mb-14 md:mb-16">
            <p className="text-4xl font-boldx mb-3 max-w-5xl mx-auto md:text-6xl">
              Join us in making a meaningful impact
            </p>
            <p className="text-sm md:text-base">
              Our fingerprints on the lives we touch never fade.
            </p>
          </div>
          <div className="flex flex-col gap-3 max-w-[180px] mx-auto sm:flex-row">
            {/* <button className="pry-cta-button py-5">Donate Today</button> */}
            <button className="border-yellow-600 uppercase border-[2px] rounded-full w-full py-5">
              Volunteer
            </button>
          </div>
        </div>
      </section>

      <Newsletter />
    </main>
  );
}

export default About;
